import Vue from 'vue';
import { mapGetters } from 'vuex';
import filters from '@razlet/sdk/lib/filters/base';
import { isNotNull, isFalsy, isNumber, isString, isObject, isValidObject, isArray, isValidArray } from '@razlet/sdk/lib/utils/helpers';
import { markersController } from '@razlet/sdk/lib/utils/markers';

const globalVars = {
  showAgreement: process.env.type !== 'agent',
  agreementLink: '/agreement',
  sendMetaMarkers: true,
  newRequestsEnabled: true,
  openError: {},
  mobile: false,
  hideBonusCard: false,
  showAirplanes: true,
};

const scopedVars = {
  currentSearch: null,
  hideMultiCity: false,
  hideHistoryOnMainPage: false,
  defaultPricings: { avia:'a_KJA.0000.MOW_1000_e', bus:'b_17.0000.99_1000_e' },
  transportTypes: /\/\/api.razlet.ru/.test(process.env.asb3URL) ? ['avia'] : ['avia', 'bus'],
  fillFromHistory: process.env.type !== 'agent',
};

Vue.mixin({
  provide() {
    return scopedVars;
  },
  data() {
    return {
      mainPhone: '+7 (391) 222-3-222',
      globalMountKey: 0,
      phoneLink: 'tel:+73912223222',
      isRu: true,
      isBonuses: false,
    };
  },
  computed: {
    ...mapGetters(['isMobile', 'isOffline', 'isInitialOffline', 'user', 'currentTheme']),
    isDev() {
      return !/\/\/api.razlet.ru/.test(process.env.asb3URL);
    },
    showLogin() {
      return process.env.auth !== 'none';
    },
    isRolesLoaded() {
      return !!(this.user?.model?.roles?.length);
    },
    userRoles() {
      if (!this.isRolesLoaded) return [];
      return this.user.model.roles;
    },
    userData() {
      return this.isUser ? this.user.model : {};
    },
    isAgent() {
      return this.isUser && this.isRolesLoaded && this.userRoles.includes('ROLE_AGENT');
    },  
    isAgentSite() {
      return process.env.type === 'agent';
    }, 
    isRequestsAvailable() {
      return this.isUser && (this.isCorporate || this.isAgent);
    },
    isCorporate() {
      return this.isUser && this.isRolesLoaded && this.userRoles.includes('ROLE_CORPORATE');
    },
    isAirportCashier() {
      return this.isUser && this.isRolesLoaded && this.userRoles.includes('ROLE_AIRPORT_CASHER');
    },
    isCashier() {
      return this.isUser && this.isRolesLoaded && this.userRoles.includes('ROLE_CASHER');
    },
    isAdmin() {
      return this.isUser && this.isRolesLoaded && this.userRoles.includes('ROLE_ADMIN');
    },
    isParseDocumentAllowed() {
      return this.isDev && (this.isCashier || this.isAdmin);
    },
    showOrderPayments() {
      return this.isCashier || this.isAirportCashier;
    },
    isKg() {
      return process.env.site && process.env.site === 'kg';
    },
    isUser() {
      return typeof this.$store.state.user !== 'undefined' && this.user.model && !!Object.keys(this.user.model).length;
    },
    currency() {
      return process.env.currency || '';
    },
    userCurrency() {
      return this.isUser ? this.user.model.currency : this.currency;
    },
    officeUrl() {
      return process.env.officeURL;
    },
    isNewYear() {
      const DAYS_BEFORE_NY = 14;
      const DAYS_AFTER_NY = 7;

      const today = new Date();

      const before = new Date(today.getFullYear(), 11, 31 - DAYS_BEFORE_NY, 0, 0, 0);
      const after = new Date(today.getFullYear() + 1, 0, 1 + DAYS_AFTER_NY, 0, 0, 0);

      return today >= before && today <= after;
    },
  },
  mounted() {
    this.changeColorScheme();
  },
  methods: {
    changeColorScheme () {
      const root = document.getElementsByTagName('html')[0];
      root.className = this.currentTheme;
    },
    async shareCurrentUrl () {
      const origin = 'https://razlet.ru';
      await this.$shareUrl(origin + this.$route.fullPath);
    },
  },
});

Vue.directive('click-outside', {
  bind (el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      // here I check that click was outside the el and his children
      if (!(el === event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent);
  },
  unbind (el) {
    document.body.removeEventListener('click', el.clickOutsideEvent);
  },
});

Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key]);
});

const COUNTRIES = {
  RUB: 'ru',
  USD: 'en',
  MNT: 'mn',
  'KGS': 'ru-KG',
  'KGS-kg': 'ru-KG',
  'KGS-ru': 'ru-KG',
  'KGS-en': 'ru-US',
};

const getKGCurrency = (currency) => `${currency}-${Vue.prototype.$locale}`;
const getCurrency = (currency) => currency === 'KGS' ? getKGCurrency(currency) : currency;

Vue.prototype.$localizeCurrency = (value, currency) => `${Vue.prototype.$localize.n(value, 'currency', COUNTRIES[getCurrency(currency)])}`;

Vue.prototype.$markersStore = markersController;

const app = new Vue({});


Object.keys(globalVars).forEach(key => {
  app[key] = globalVars[key];

  if (typeof Vue.prototype[`$${key}`] === 'undefined') {
    Object.defineProperty(Vue.prototype, `$${key}`, {
      get() {
        return app[key];
      },
      set(value) {
        app[key] = value;
      },
    });
  }
}); 

Vue.prototype.$isNotNull = isNotNull;
Vue.prototype.$isFalsy = isFalsy;
Vue.prototype.$isNumber = isNumber;
Vue.prototype.$isString = isString;
Vue.prototype.$isObject = isObject;
Vue.prototype.$isValidObject = isValidObject;
Vue.prototype.$isArray = isArray;
Vue.prototype.$isValidArray = isValidArray;
